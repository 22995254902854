import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

import components, {Layout} from '../components/index';
import {htmlToReact} from '../utils';
import {buildAppUrl} from "../utils/app-utils";

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

function App(props) {
    const baseAppUrl = 'https://app.skarga.help';
    const [appUrl, setAppUrl] = useState(baseAppUrl);

    useEffect(() => {
        setAppUrl(buildAppUrl(baseAppUrl, document.location.href));

        if (!window.skargaAppLoaded) {
            window.addEventListener(
                'message',
                (e) => {
                    if (!e.data || isNaN(e.data.skargaAppFrameHeight)) return;
                    document.querySelector('#skargaApp').style.height = e.data.skargaAppFrameHeight + 'px';
                },
                false,
            );
            window.skargaAppLoaded = true;
        }
    }, []);

    return (
        <Layout {...props}>
            {_.map(_.get(props, 'pageContext.frontmatter.sections', null), (section, section_idx) => {
                let component = _.upperFirst(_.camelCase(_.get(section, 'type', null)));
                let Component = components[component];
                return (
                    <Component key={section_idx} {...props} section={section} site={props.pageContext.site}/>
                )
            })}
            <div className="post-content">
                {htmlToReact(_.get(props, 'pageContext.html', null))}
                <iframe id="skargaApp"
                        title="Skarga.help Генератор жалоб"
                        src={appUrl}
                        frameBorder="0"
                        scrolling="no"
                        width="100%"
                        height="720px"
                        style={{minHeight: 800}}
                />
            </div>

        </Layout>
    );
}

export default App;
